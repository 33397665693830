import './App.css'
import { useState } from 'react'
import { useMath } from './useMath'

function App() {
	const [operator, setOperator] = useState('?')
	const [value, setValue] = useState({
		first: 0,
		second: 0,
		result: 0,
	})
	const [show, setShow] = useState(false)
	const [win, setWin] = useState(false)

	const { setIsReady, operations } = useMath({ setValue })

	const checkResult = () => {
		// eslint-disable-next-line
		if (eval(value.first + operator + value.second) === Number(value.result)) {
			console.log('WIn')
			setShow(true)
			setWin(true)
			setTimeout(() => {
				setShow(false)
				setIsReady(false)
				setOperator('?')
			}, 1500)
		} else {
			setOperator('?')
			setShow(true)
			setWin(false)
			setTimeout(() => setShow(false), 1500)
		}
	}

	return (
		<div className='wrapper'>
			{show && <div className='show'>{win ? 'Правильно' : 'Не правильно'}</div>}

			<ul className='btns'>
				{operations &&
					operations.map(operation => (
						<button
							className='btns__btn button'
							onClick={() => setOperator(operation)}
						>
							{operation}
						</button>
					))}
			</ul>
			<div className='task-box'>
				Выбери операцию и реши уравнение
				<div className='task-box__operations'>
					<span>{value.first}</span>
					<input readOnly value={operator} />
					<span>{value.second}</span>
					<span>=</span>
					<span>{value.result}</span>
				</div>
				<button
					onClick={checkResult}
					type='submit'
					className='task-box__button button'
					disabled={operator === '?'}
				>
					Проверить
				</button>
			</div>
		</div>
	)
}

export default App
