import { useState, useEffect, useMemo } from 'react'

export const useMath = ({ setValue }) => {
	const coefNum = 20

	const [randomNum, setRandomNum] = useState(
		Math.floor(Math.random() * coefNum)
	)
	const [randomNum2, setRandomNum2] = useState(
		Math.floor(Math.random() * coefNum)
	)
	const [randomNum3, setRandomNum3] = useState(
		Math.floor(Math.random() * coefNum)
	)

	const operations = useMemo(() => ['+', '-', '*', '/'], [])

	const [isReady, setIsReady] = useState(false)
	useEffect(() => {
		if (!isReady) {
			for (let i = 0; i < operations.length; i++) {
				// eslint-disable-next-line
				if (eval(randomNum + operations[i] + randomNum2) === randomNum3) {
					setIsReady(true)
					setValue({
						first: randomNum,
						second: randomNum2,
						result: randomNum3,
					})
				}
			}
			setRandomNum(Math.floor(Math.random() * coefNum))
			setRandomNum2(Math.floor(Math.random() * coefNum))
			setRandomNum3(Math.floor(Math.random() * coefNum))
		}
	}, [isReady, randomNum, randomNum2, randomNum3, operations, setValue])

	return { setIsReady, operations }
}
